import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import BaseModal from "components/helpers/modal";
import Show from "../show";

function Modal({onClose, record}) {
  return (
    <BaseModal onClose={onClose} title={`Meet ${record.name}`}>
      <Show record={record} />
      <div className="border-t my-6 -mx-6" />
      <div className="text-center">
        <Link className="btn-theme" to={`/candidates/${record.id}`}>Sponsor {record.name}</Link>
      </div>
    </BaseModal>
  );
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default Modal;
