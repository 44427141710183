import {useNavigate, useParams, Link} from "react-router-dom";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {createNotification} from "app/actions/notifications";
import Content from "components/helpers/action-text/content";
import Candidate from "components/helpers/candidates/show";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";
import useContent from "lib/hooks/use-content";

const url = "/api/public/sponsorships";

export default function Show() {
  const {dispatch, user} = useAppContext();
  const accountContent = useContent("Sponsorship", "Account Content");
  const content = useContent("Sponsorship", "Content");
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: _data, isLoading: loading} = useSWR({url: `/api/public/candidates/${id}`});
  const record = loading ? null : _data.record;
  const {trigger} = useSWRMutation({method: "POST", url});
  if(loading) { return <Loading />; }

  const onClick = () => {
    trigger({params: {record: {candidate_id: id}}}).then((data) => {
      dispatch(createNotification({
        content: data.message,
        type: data.success ? "success" : "error"
      }));

      if(data.success) { navigate("/sponsorships"); }
    });
  };

  return (
    <div className="container">
      <div className="max-w-screen-lg mx-auto">
        <h1 className="text-center">Sponsor {record.name}</h1>
        <div className="flex gap-2">
          <div className="flex-1">
            {user ? (
              <>
                <Content html={content} />
                <div className="flex gap-2 justify-center mt-2">
                  <button className="btn btn-theme" onClick={onClick} type="button">Sponsor</button>
                </div>
              </>
            ) : (
              <>
                <Content html={accountContent} />
                <div className="flex gap-2 justify-center mt-2">
                  <Link className="btn btn-theme" to={`/session?candidate_id=${record.id}`}>Sign In</Link>
                  <Link className="btn btn-blue" to={`/register?candidate_id=${record.id}`}>Sign Up</Link>
                </div>
              </>
            )}
          </div>
          <Candidate className="flex-1" record={record} />
        </div>
      </div>
    </div>
  );
}
