import PropTypes from "prop-types";
import Checkbox from "components/helpers/form/checkbox";
import ContentEditor from "components/helpers/form/content-editor";
import DateField from "components/helpers/form/date";
import File from "components/helpers/form/file";
import Label from "components/helpers/form/label";
import FormWithFiles from "components/helpers/form/with-files";

function Form({onChange, onSubmit, value}) {
  return (
    <div className="max-w-sm mx-auto">
      <FormWithFiles onSubmit={onSubmit}>
        <div className="mb-4">
          <Checkbox
            checked={value("admin", false)}
            id="record-admin"
            label="Admin"
            name="admin"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <Checkbox
            checked={value("coordinator", false)}
            id="record-coordinator"
            label="Coordinator"
            name="coordinator"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-first-name">First Name</label>
          <input
            id="record-first-name"
            name="first_name"
            onChange={onChange}
            placeholder="Fatma"
            required={true}
            type="text"
            value={value("first_name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-last-name">Last Name</label>
          <input
            id="record-last-name"
            name="last_name"
            onChange={onChange}
            placeholder="Prats"
            required={true}
            type="text"
            value={value("last_name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-email">Email</label>
          <input
            id="record-email"
            name="email"
            onChange={onChange}
            placeholder="fatma@tomprats.com"
            required={true}
            type="email"
            value={value("email")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-birthdate">Birthdate</label>
          <DateField
            id="record-birthdate"
            name="birthdate"
            onChange={onChange}
            value={value("birthdate")}
          />
        </div>
        {value("id") && (
          <div className="mb-4">
            <label htmlFor="record-image">Image</label>
            <File id="record-image" name="image" onChange={onChange} />
            {value("image") && <img alt="Profile" src={value("image")} />}
          </div>
        )}
        <div className="mb-4">
          <Label htmlFor="record-notes-disabled" tooltip="Only visible by admin and coordinators">Notes</Label>
          <ContentEditor
            id="record-notes"
            name="notes"
            onChange={onChange}
            value={value("notes")}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </FormWithFiles>
    </div>
  );
}

Form.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default Form;
