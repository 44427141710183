import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {setPage} from "app/actions/page";
import useAppContext from "lib/hooks/use-app-context";
import Candidates from "./candidates";
import Default from "./default";
import Home from "./home";

const getPage = ({pages, pathname}) => {
  if(pathname === "/") {
    return pages.find(({path}) => path === "home") || {
      content: "",
      name: "Home",
      path: "home",
      template: "home"
    };
  }

  return pages.find(({path}) => `/${path}` === pathname);
};

export default function Template() {
  const location = useLocation();
  const {dispatch, page, pages} = useAppContext();

  useEffect(() => {
    dispatch(setPage(getPage({pages, pathname: location.pathname})));
  }, [location.pathname, pages]);

  if(!page) { return null; }

  switch(page.template) {
    case "Candidates": return <Candidates />;
    case "Home": return <Home />;
    default: return <Default />;
  }
}
