import PropTypes from "prop-types";
import Checkbox from "components/helpers/form/checkbox";
import ContentEditor from "components/helpers/form/content-editor";
import Label from "components/helpers/form/label";

const templates = ["Candidates", "Default", "Home"];

function Form({onChange, onSubmit, value}) {
  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <Checkbox
            checked={value("active", false)}
            id="record-active"
            label="Active"
            name="active"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <Label htmlFor="record-rank" tooltip="The lower the rank, the higher the record is shown">Rank</Label>
          <input
            id="record-rank"
            name="rank"
            onChange={onChange}
            placeholder="100"
            required={true}
            type="text"
            value={value("rank")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-template">Template</label>
          <select
            id="record-template"
            name="template"
            onChange={onChange}
            required={true}
            value={value("template")}
          >
            {templates.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="Contact"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-path">Path</label>
          <input
            id="record-path"
            name="path"
            onChange={onChange}
            placeholder="contact"
            required={true}
            type="text"
            value={value("path")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-content-disabled">Content</label>
          <ContentEditor
            id="record-content"
            name="content"
            onChange={onChange}
            value={value("content")}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

Form.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default Form;
