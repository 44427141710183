import PropTypes from "prop-types";
import useSWR from "swr";
import Candidate from "components/helpers/candidates/show";
import Loading from "components/pages/loading";

const url = "/api/public/sponsorships";

function Show({record: {candidate: {coordinator, ...record}}}) {
  return (
    <div className="flex gap-4">
      <Candidate className="flex-1" record={record} />
      {coordinator && (
        <div className="flex flex-1 gap-4 w-full">
          {coordinator.image && (
            <img alt={coordinator.name} className="max-w-[100px] md:max-w-[200px] rounded" src={coordinator.image} />
          )}
          <div>
            <h6 className="font-semibold">Coordinator</h6>
            {[
              {label: "Name", value: coordinator.name},
              {label: "Email", value: coordinator.email}
            ].filter(({value}) => value).map(({label, value}) => (
              <div key={label}><b>{label}:</b> {value}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

Show.propTypes = {
  record: PropTypes.shape({
    candidate: PropTypes.shape({
      coordinator: PropTypes.shape({
        email: PropTypes.string,
        image: PropTypes.string,
        name: PropTypes.string
      })
    }).isRequired
  }).isRequired
};

export default function List() {
  const {data: _data, isLoading: loading} = useSWR({url});
  const records = loading ? null : _data.records.filter(({candidate}) => candidate);
  if(loading) { return <Loading />; }

  const active = records.filter((record) => record.active);
  const inactive = records.filter((record) => !record.active);

  return (
    <div className="container">
      <div className="max-w-screen-lg mx-auto">
        <h1 className="text-center">Sponsorships</h1>
        {active.length > 0 ? (
          <div className="flex flex-col gap-8">
            {active.map((record) => <Show key={record.id} record={record} />)}
          </div>
        ) : (
          <h6 className="mb-4 text-center">None</h6>
        )}
        {inactive.length > 0 && (
          <>
            <h3 className="text-center">Past Sponsorships</h3>
            <div className="flex flex-col gap-8">
              {inactive.map((record) => <Show key={record.id} record={record} />)}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
